import React, { useState, useEffect } from "react";
import Ellipse from "../../Assests/Ellipse.png";
import { EllipsisVertical } from "lucide-react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { t } from "i18next";
import i18n from "../../i18n";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { getRemoveToken } from "../../Service/Auth/Revoke";
import { getAPIData } from "../../Service/Get/Get";
import Logo from "../../Assests/Logo.png";
import Logos from "../../Assests/Logos.png";
import config from "../../Config/Config";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const base = process.env.PUBLIC_URL;
const Header = ({ title, widths }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [datas, setData] = useState({}); // Initialize state as an object
  const [loading, setLoading] = useState(false); // Loading state
  const [addLoading, setAddLoading] = useState(false);

  const GetData = async () => {
    setLoading(true);
    try {
      const res = await getAPIData("my"); // Fetch the data
      setData(res);
    } catch (err) {
      console.log("Error fetching data:", err.response);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    GetData(); // Fetch the data on component mount
  }, []);

  const autoLogout = () => {
    if (!config.token) {
      localStorage.clear();
      sessionStorage.clear();
      getRemoveToken();
      window.location.href = base + "/app/";
      window.location.reload(1);
    }
  };

  useEffect(() => {
    autoLogout();
  }, []);

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    getRemoveToken();
    if (!cookies.get("Token")) {
      localStorage.removeItem("popupMultiples");
    }
  };

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const mainLogoutButton = (e) => {
    e.preventDefault();
    onLogout();
    clearCacheData();
  };

  return (
    <div className="border-b-2 border-gray-300 bg-white flex items-center justify-between p-4 relative">
      {/* Left section (logo + title) */}
      <div className="flex items-center gap-4">
        {/* Logo */}
        <div
            className={`flex-shrink-0 border-r-2 border-[#E4E4E7] ${
                widths !== 5
                    ? `w-[${widths}vw] pl-1 pr-6`
                    : `w-[${widths - 2}vw] pl-2 pr-6`
            }`}
        >
          <img
            src={widths !== 5 ? Logo : Logos}
            className={`cursor-pointer ${widths !== 5 ? "h-auto w-[175px]":"h-7 w-auto"}`}
            alt="logo"
            onClick={() => navigate(base+"/esg-dashboard")}
          />
        </div>

        {/* Title section */}
        {location.pathname !== "/evhub/setting" && (
          <h1 className="text-2xl font-semibold">
            {title}
          </h1>
        )}
      </div>

      {/* Right section (profile + menu) */}
      <div className="flex items-center gap-2">
        <img src={Ellipse} alt="profile" className="w-[2.48rem] h-[2.48rem]" />
        <Popover className="p-0 relative">
          <PopoverTrigger asChild>
            <button>
              <HiOutlineDotsVertical size={20} />
            </button>
          </PopoverTrigger>
          <PopoverContent className="w-56 mr-10 relative top-2 z-[2000]">
            <div className="space-y-1 px-2 py-2 flex flex-col border-b">
              <span className="font-sans text-[14px] font-[400] leading-5 text-left">
                {datas?.name}
              </span>
              <span className="font-sans text-[9px] font-[400] leading-5 text-left">
                {datas?.email}
              </span>
            </div>
            <div
              className="space-y-1 px-2 py-2 hover:bg-[#f4f4f5] hover:rounded-md border-b font-sans text-[14px] font-[400] leading-5 text-left"
              onClick={() => navigate(base+"/setting")}
            >
              Settings
            </div>
            <div
              className="space-y-1 px-2 py-2 hover:bg-[#f4f4f5] rounded-md font-sans text-[14px] font-[400] leading-5 text-left"
              onClick={mainLogoutButton}
            >
              Log Out
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default Header;