import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {t} from "i18next"
import { getAPIData } from "../../Service/Get/Get";
import combine from "../../Assests/combine.png";
import partypopper from "../../Assests/partypopper.png";
import newspaper from "../../Assests/newspaper.png";
import leafygreen from "../../Assests/leafygreen.png";
import myIcon from "../../Assests/myICONs.svg";
import codesandbox from "../../Assests/Vector.png";
import car from "../../Assests/car1.png";
import filetext from "../../Assests/filetext.png";
import leaf from "../../Assests/leaf.png";
import chevrondown from "../../Assests/chevrondown.png";
import Header from "../header/Header";
import "./Sidebar.css";
const baseUrl = process.env.PUBLIC_URL;
const datas = [
  { name: t("Route.ESG Dashboard"), link: baseUrl + "/esg-dashboard", icon: leafygreen },
  {
    name: t("Route.EV Fleet Assessment"),
    link: baseUrl + "/assesment",
    icon: codesandbox,
  },
  {
    name: t("Route.EV Tools"),
    link: baseUrl + "/ev-tools",
    icon: newspaper,
  },
  {
    name: t("Route.EV News"),
    link: baseUrl + "/ev-news",
    icon: newspaper,
  },

  {
    name: t("Route.EV Partners"),
    link: baseUrl + "/ev-partner",
    icon: partypopper,
  },
  {
    name: t("Route.My Fleet Electrification"),
    link: baseUrl + "/evr-process",
    icon: combine,
  },
  {
    name: t("Route.My Fleet"),
    link: baseUrl + "/fleet-vehicle-list",
    icon: myIcon,
    children: [
      {
        name: t("Route.My vehicles"),
        link: baseUrl + "/fleet-vehicle-list",
        icon: car,
      },
      {
        name: t("Route.Vehicle documents"),
        link: baseUrl + "/vehicle-documents",
        icon: filetext,
      },
      {
        name: t("Route.Fleet emissions"),
        link: baseUrl + "/fleet-esg-data",
        icon: leaf,
      },
    ],
  },
];

const Sidebar = ({ children }) => {
  const location = useLocation();
  const [data, setData] = useState("EV assessment");
  const [data1, setData1] = useState("Fleet vehicle list");
  const [isNarrow, setIsNarrow] = useState(false);
  const [widths, setWidths] = useState(20); // Set default width size
  const panelRef = useRef(null);
  const [openItems, setOpenItems] = useState({});
  const isIframe = window.self !== window.top;
  // Set active menu item based on route
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = datas.find((item) => item.link === currentPath);
    if (currentItem) {
      setData(currentItem.name);
    }
  }, [location.pathname]);

  const handleResize = (number) => {
    setWidths(number);
    if (panelRef.current) {
      const sidebarWidth = panelRef.current.offsetWidth;
      setIsNarrow(sidebarWidth <= 100);
    }
  };

  useEffect(() => {
    getAPIData("_mpk_list").then((data) => {
      if (data?.length > 0) {
        window.sessionStorage.setItem("mpk_list", JSON.stringify(data));
      }
    });

    getAPIData("_vehicle_group").then((data) => {
      if (data?.length > 0) {
        window.sessionStorage.setItem("vehicle_group", JSON.stringify(data));
      }
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", () => handleResize(widths));
    handleResize(widths);

    return () => {
      window.removeEventListener("resize", () => handleResize(widths));
    };
  }, [widths]);
  useEffect(() => {
    const currentPath = location.pathname;

    // Set the active link for both "/assesment" and "/survey-summary"
    if (currentPath === "/assesment" || currentPath === "/survey-summary") {
      setData("EV Assessment");
    } else {
      const currentItem = datas.find((item) => item.link === currentPath);
      if (currentItem) {
        setData(currentItem.name);
      }
    }
  }, [location.pathname]);
  // Handle expandable submenu toggle
  const toggleSubmenu = (name) => {
    setOpenItems((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <div className="sidebar-container flex flex-col relative">
      <>
        {" "}
        {location.pathname !== "/" &&
          location.pathname !== "/register" &&
          !isIframe && <Header title={data} widths={widths} />}{" "}
      </>
      <ResizablePanelGroup direction="horizontal" className="h-full">
        {location.pathname !== "/evhub/setting" && (
          <>
            {!isIframe && (
              <ResizablePanel
                defaultSize={20}
                minSize={5}
                maxSize={18}
                className={`h-screen resizable-panel ${
                  isNarrow ? "narrow" : ""
                }`}
                ref={panelRef}
                onResize={(number) => handleResize(number)}
              >
                <div className="sidebar">
                  <div className="flex flex-col gap-2 p-4 items-start">
                    {datas?.map((item, i) => (
                      <>
                        <div key={i} className="sidebar-item">
                          <div
                            onClick={() => {
                              if (item?.children) {
                                toggleSubmenu(item.name);
                              } else {
                                setData(item.name);
                              }
                            }}
                          >
                            <NavLink
                              to={item?.link}
                              className={({ isActive }) =>
                                `flex items-center w-full cursor-pointer ${
                                  isActive ||
                                  (item.name === "EV Assessment" &&
                                    (location?.pathname === "/assesment" ||
                                      location?.pathname === "/survey-summary"))
                                    ? "active"
                                    : ""
                                }`
                              }
                              onClick={() => {
                                if (!item.children) {
                                  setData(item.name);
                                }
                              }}
                            >
                              <div className="flex justify-between items-center">
                                <div className="flex gap-2">
                                  {item.icon &&
                                    (widths === 5 ? (
                                      <TooltipProvider>
                                        <Tooltip delayDuration={200}>
                                          <TooltipTrigger asChild>
                                            <img
                                              src={item.icon}
                                              className="h-5 w-5"
                                              alt={`${item.name} icon`}
                                            />
                                          </TooltipTrigger>
                                          <TooltipContent side="right">
                                            <p>{item.name}</p>
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>
                                    ) : (
                                      <img
                                        src={item.icon}
                                        className="h-5 w-5"
                                        alt={`${item.name} icon`}
                                      />
                                    ))}
                                  {widths !== 5 && <span>{item.name}</span>}
                                </div>
                                {item.children && widths !== 5 && (
                                  <img
                                    src={chevrondown}
                                    className="h-5 w-5"
                                    alt="chevrondown"
                                  />
                                )}
                              </div>
                            </NavLink>
                          </div>
                        </div>

                        {item.children && openItems[item.name] && (
                          <div className="ml-2 flex flex-col gap-4 relative top-1 ">
                            {item.children.map((child, index) => (
                              <div key={index} className="sidebar-item1">
                                <NavLink
                                  key={index}
                                  to={child.link}
                                  className={({ isActive }) =>
                                    `flex items-center gap-2 w-full cursor-pointer ${
                                      isActive ? "active" : ""
                                    }`
                                  }
                                  onClick={() => setData1(child.name)}
                                >
                                  {widths === 5 ? (
                                    <TooltipProvider>
                                      <Tooltip delayDuration={200}>
                                        <TooltipTrigger asChild>
                                          <img
                                            src={child.icon}
                                            className="h-5 w-5"
                                            alt={`${child.name} icon`}
                                          />
                                        </TooltipTrigger>
                                        <TooltipContent side="right">
                                          <p>{child.name}</p>
                                        </TooltipContent>
                                      </Tooltip>
                                    </TooltipProvider>
                                  ) : (
                                    <img
                                      src={child.icon}
                                      className="h-5 w-5"
                                      alt={`${child.name} icon`}
                                    />
                                  )}
                                  {widths !== 5 && <span>{child.name}</span>}
                                </NavLink>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </ResizablePanel>
            )}
            <ResizableHandle withHandle />
          </>
        )}

        <ResizablePanel defaultSize={100} className="content-panel">
          {!isIframe ? (
            <div className="content-area bg-[#F5F5F5]">{children}</div>
          ) : (
            <div className="w-full h-full">{children}</div>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default Sidebar;
